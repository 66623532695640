import 'lib-flexible'
import Vue from 'vue'
import MetaInfo from 'vue-meta-info'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue'
import './assets/style/index.scss'
import './components'
import router from './router'
// oss
import { assetsBaseUrl } from '@/utils/config'

Vue.use(MetaInfo);
Vue.use(VueCompositionAPI)
Vue.use(VueAwesomeSwiper)
Vue.prototype.$assetsBaseUrl = assetsBaseUrl
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
