<template>
  <div>
    <svg :style="{color, fontSize: size, width, height}" aria-hidden="true" class="icon">
      <use :xlink:href="`#${icon}`" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String
    },
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  }
}
</script>